
@tailwind base;
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: rgb(177, 251, 215); /*#54d59b;  #42A77A;*/
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #ffffff;
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Zen Maru Gothic', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #303030; */
  /* background-color: #54D59B; */
  background: rgb(177, 251, 215);
  background: radial-gradient(
    circle,
    rgba(177, 251, 215, 1) 0%,
    rgba(84, 213, 155, 1) 100%
  );
  color: #fefefe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.make-font-400{
  font-weight: 400
}
.make-font-500{
  font-weight: 500
}
.make-font-600{
  font-weight: 600
}
.make-font-700{
  font-weight: 700
}
.make-font-800{
  font-weight: 800
}